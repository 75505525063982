import { Badge, Button, Checkbox, Col, Form, Input, Modal, Row, Select, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileData,
  getUserselectedInterface,
  getUserselectedNewsFeedLanguages,
  selectingInterfaceLanguage,
  selectingNewsFeedLanguage,
  updateLocation,
  updateSelectedCategories,
} from "../../Store/ProfileSettingsSlice";
import { Link } from "react-router-dom";
import TranslateWord from "../sharedComponents/TranslateWord";
import BaseUrl from "../../utils/config";
import { getAutoPlayData, updateAutoPlayData } from "../../Store/AutoPlaySlice";
let selectedInterfaceId = [];
let interfaceLId = null;
let newsFeedLId = null;
let interfaceLang = [];
const SettingsModel = ({ openFlag, handleCloseModel }) => {
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
  const autoFlag = autoPlayStatus
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const [selectingInterfaceForm] = Form.useForm();
  const [selectingNewsFeedForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(openFlag);
  const [interfaceLangId, setInterfaceLangId] = useState(null);
  const [newsFeedLangId, setNewsFeedLangId] = useState(null);
  const [categoriesData, setCategoriesData] = useState(null);
  const [isSubCategoryModalOpen, setIsSubCategoryModalOpen] = useState(false);
  const [selectedLinks, setSelectedLinks] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectAllLable, setselectAllLable] = useState("Select All");
  const [isAutoPlay, setIsAutoPlay] = useState(autoFlag);

  const [subcatData, setSubcatData] = useState({});
  const [selectedCounts, setSelectedCounts] = useState({});
  const { Option } = Select;
  let user = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedInterfaceData, userSelectedNewsFeedData, userProfileData } = useSelector(
    (state) => state.profileSettingsList
  );
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);

  const { data: categoryData } = useSelector((state) => state.categoriesList);
  useEffect(() => {
    dispatch(getUserselectedInterface(user?.user_id));    
  }, []);

 
  // useEffect(() =>{
  //   dispatch(getAutoPlayData(user?.user_id));
  // },[])
  useEffect(() => {
    userProfileData?.selected_categories?.map((cat) => {
      const cat_id = cat?.category_id;
      const subCategoryIds = cat.str_sub_categories_snos;
      subCategoryIds?.forEach((sub_id) => {
        setSelectedLinks((prevSelected) => {
          const categorySelected = prevSelected[cat_id] || {};
          const isSelected = categorySelected[sub_id];

          const updatedCategorySelected = {
            ...categorySelected,
            [sub_id]: true,
          };

          return {
            ...prevSelected,
            [cat_id]: updatedCategorySelected,
          };
        });
      });
    });
  }, [userProfileData]);
  useEffect(() => {
    interfaceLId = userSelectedInterfaceData
      ?.filter((lang) => lang?.selected)
      ?.map((lang) => lang.interface_lang_id)?.[0];
    setInterfaceLangId(interfaceLId);
    newsFeedLId = userSelectedNewsFeedData?.filter((lang) => lang?.selected)?.map((lang) => lang.newsfeed_lang_id);
    setNewsFeedLangId(newsFeedLId);
  }, [interfaceLId, userSelectedInterfaceData, newsFeedLId]);
  useEffect(() => {
    const cateList = categoryData?.filter((record) => record && record?.category_name);

    const filteredCategories = cateList?.filter((cat) => {
      return cat.interface_language?.name === selectedInterfaceLanguage?.name;
    });

    console.log("filteredCategories", filteredCategories);
    setCategoriesData(filteredCategories);
  }, [categoryData]);

  //   useEffect(() =>{
  //     interfaceLang = userSelectedInterfaceData?.filter((lang) =>{
  //       if(lang?.selected) {
  //        return lang
  //       }
  //    })
  //    const filterInterfaceWIse = categoryData?.filter((cat) =>{
  //     if(cat?.interface_language?.name === interfaceLang?.[0]?.name) return cat
  //  })
  //  console.log('filterInterfaceWIse',filterInterfaceWIse)
  //   },[])

  const showModal = (ip_category_id) => {
    setIsSubCategoryModalOpen(true);
    setSubcatData(ip_category_id);
    let data = categoryData?.find((cat) => cat.category_id === ip_category_id);
    setSelectedCategory(data);
    console.log("selectedCategory", selectedCategory);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    handleCloseModel();
  };
  const submitInterfaceLanguageForm = (values) => {
    const payload = {
      interface_lang_id: values?.interfaceLang,
      user_id: user?.user_id,
    };
    dispatch(selectingInterfaceLanguage(payload));
  };

  const submitLocation = (values) => {
    const payload = {
      district: values?.district,
      location: values?.location,
      mandal: values?.mandal,
      state: values?.state,
      user_id: user?.user_id,
      village: values?.village,
    };
    dispatch(updateLocation(payload));
  };

  const submitNewsFeedLanguageForm = (values) => {
    console.log("values", values);
    let feedPayload = [];
    values?.newsFeedLang?.map((val) => {
      feedPayload.push({ newsfeed_lang_id: val });
    });
    const payload = {
      user_newsfeed_languages: feedPayload,
      user_id: user?.user_id,
    };
    dispatch(selectingNewsFeedLanguage(payload));
  };

  useEffect(() => {
    const selectedLinksCopy = selectedLinks;
    for (const categoryId in selectedLinksCopy) {
      if (Object.hasOwnProperty.call(selectedLinksCopy, categoryId)) {
        const subCategories = selectedLinksCopy[categoryId];

        for (const subCategoryId in subCategories) {
          if (Object.hasOwnProperty.call(subCategories, subCategoryId) && subCategories[subCategoryId] === false) {
            delete selectedLinksCopy[categoryId][subCategoryId];
          }
        }

        // If there are no more sub-categories for the current category, delete the category itself
        if (Object.keys(selectedLinksCopy[categoryId]).length === 0) {
          delete selectedLinksCopy[categoryId];
        }
      }
    }

    console.log(selectedLinks);
    console.log(selectedLinksCopy);

    // Update selected counts whenever selected links change
    const updatedSelectedCounts = {};
    Object.keys(selectedLinks).forEach((categoryId) => {
      const categorySelected = selectedLinks[categoryId];
      const count = Object.values(categorySelected).filter(Boolean).length;
      updatedSelectedCounts[categoryId] = count;
    });
    setSelectedCounts(updatedSelectedCounts);
  }, [selectedLinks]);

  const handleLinkClick = (categoryId, subCategoryId) => {
    setSelectedLinks((prevSelected) => {
      const categorySelected = prevSelected[categoryId] || {};
      const isSelected = categorySelected[subCategoryId];

      const updatedCategorySelected = {
        ...categorySelected,
        [subCategoryId]: !isSelected,
      };

      return {
        ...prevSelected,
        [categoryId]: updatedCategorySelected,
      };
    });
  };

  const handleSelectAll = (categoryId) => {
    if (selectAllLable === "Select All") setselectAllLable("Deselect All");
    if (selectAllLable === "Deselect All") setselectAllLable("Select All");
    const allSubcategoryIds = selectedCategory?.str_sub_categories_snos || [];
    const categorySelected = selectedLinks[categoryId] || {};
    const allSelected = Object.keys(categorySelected).length === allSubcategoryIds.length;

    const updatedCategorySelected = {};
    if (!allSelected) {
      allSubcategoryIds.forEach((id) => {
        updatedCategorySelected[id] = true;
      });
    }

    setSelectedLinks((prevSelected) => ({
      ...prevSelected,
      [categoryId]: updatedCategorySelected,
    }));
  };
  const handleSubCategoryOk = () => {
    setIsSubCategoryModalOpen(false);
    setselectAllLable("Select All");
  };

  const handleSubCategoryCancel = () => {
    setIsSubCategoryModalOpen(false);
    setselectAllLable("Select All");
  };

  const gettingLatestUserProfileData = () => {
    dispatch(getUserProfileData(user?.user_id));
  };
  const submitCategoriesData = () => {
    const filteredData = categoriesData
      ?.map((item) => {
        const categoryId = item.category_id.toString();
        if (selectedLinks.hasOwnProperty(categoryId)) {
          const selectedCategories = selectedLinks[categoryId];
          // Get the IDs of selected sub-categories
          const selectedSubCategoryIds = Object.keys(selectedCategories);

          // Update str_sub_categories_snos with selected sub-category IDs
          const updatedSubCategorySnos = selectedSubCategoryIds.map(Number); // Convert string IDs to numbers

          const updatedSubCategoriesName = item?.sub_categories_names.filter((subCategory) => {
            return updatedSubCategorySnos.includes(subCategory.sub_category_id);
          });
          // Filter sub-categories based on selected sub-category IDs
          const updatedSubCategories = item.sub_categories_names.filter((subCategory) =>
            selectedSubCategoryIds.includes(subCategory.sub_category_id.toString())
          );

          const slectedSubCategoriesList = updatedSubCategories?.map((cat) => {
            return {
              ...cat,
              selected: true,
            };
          });
          // Update str_sub_categories_names with names of selected sub-categories
          const updatedSubCategoryNames = slectedSubCategoriesList.map((subCategory) => subCategory.sub_category_name);

          return {
            ...item,
            str_sub_categories_snos: updatedSubCategorySnos,
            str_sub_categories_names: updatedSubCategoryNames,
            sub_categories_names: slectedSubCategoriesList,
          };
        }
      })
      ?.filter(Boolean);
    const payload = {
      user_categories: filteredData,
      user_id: user?.user_id,
    };

    dispatch(updateSelectedCategories(payload)).then((action) => {
      if (updateSelectedCategories.fulfilled.match(action)) {
        message.success({
          content: "Categories Updated successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
        gettingLatestUserProfileData();
        // dispatch(getUserProfileData(user?.user_id))
      }
    });
  };

  const onChangeAutoPlay = (e) => {
    setIsAutoPlay(e.target.checked);
  };

  const submitAutoPlay = async () => {
    const payload = {
      is_scrollable: isAutoPlay,
      login_user_id: user?.user_id,
    };

    dispatch(updateAutoPlayData(payload)).then((action) => {
      if (updateAutoPlayData.fulfilled.match(action)) {
        message.success({
          content: "Updated successfully",
          duration: 5, 
          style: {
              marginTop: '20px', 
              marginBottom: '20px', 
          },
      });
      dispatch(getAutoPlayData(user?.user_id));
      }
    });
    // const response = await fetch(`${BaseUrl}/user/scrollable`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(payload),
    // });
    // const res = await response.json();   
    // if (res?.is_success === true) {
    //   message.success({
    //     content: "Successfully Updated",
    //     duration: 5,
    //     style: {
    //       marginTop: "20px",
    //       marginBottom: "20px",
    //     },
    //   });
    // }
  };
  return (
    <div>
      <Modal
        title={
          <TranslateWord key={"SETTINGS"} text={"SETTINGS"} lang={selectedInterfaceLanguage} headingStyle={true} />
        }
        width={800}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <div className="modal-body">
          <Tabs tabPosition="left" className="custom-tabs-width">
            <TabPane
              tab={
                <TranslateWord
                  key={"INTERFACE_lANGUAGE"}
                  text={"INTERFACE_lANGUAGE"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={false}
                />
              }
              key="interfaceLanguage">
              <div style={{ marginBottom: "30px", marginTop: "-3vh" }}>
                <h2>
                  {
                    <TranslateWord
                      key={"INTERFACE_lANGUAGE"}
                      text={"INTERFACE_lANGUAGE"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  }
                </h2>
              </div>
              <div>
                <Form
                  form={selectingInterfaceForm}
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={submitInterfaceLanguageForm}>
                  <Form.Item
                    name="interfaceLang"
                    label={
                      <TranslateWord
                        key={"INTERFACE_lANGUAGE"}
                        text={"INTERFACE_lANGUAGE"}
                        lang={selectedInterfaceLanguage}
                        headingStyle={false}
                      />
                    }>
                    <Select
                      defaultValue={
                        (userSelectedInterfaceData?.length !== 0 &&
                          userSelectedInterfaceData
                            ?.filter((lang) => lang?.selected)
                            ?.map((lang) => lang.interface_lang_id)?.[0]) ||
                        interfaceLId
                      }
                      // defaultValue={
                      //  interfaceLangId
                      // }
                      style={{
                        position: "relative",
                        top: "10px",
                      }}>
                      {userSelectedInterfaceData?.map((lang) => {
                        return (
                          <>
                            <Option value={lang?.interface_lang_id}>{lang?.name}</Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                      {
                        <TranslateWord
                          key={"UPDATE"}
                          text={"UPDATE"}
                          lang={selectedInterfaceLanguage}
                          headingStyle={false}
                        />
                      }
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
            <TabPane
              tab={
                <TranslateWord
                  key={"NEWS_FEED_lANGUAGE"}
                  text={"NEWS_FEED_lANGUAGE"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={false}
                />
              }
              key="newsFeedLanguage">
              <div style={{ marginBottom: "30px", marginTop: "-3vh" }}>
                <h2>
                  {
                    <TranslateWord
                      key={"NEWS_FEED_lANGUAGE"}
                      text={"NEWS_FEED_lANGUAGE"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  }
                </h2>
              </div>
              <div>
                <Form
                  form={selectingNewsFeedForm}
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={submitNewsFeedLanguageForm}>
                  <Form.Item
                    name="newsFeedLang"
                    label={
                      <TranslateWord
                        key={"NEWS_FEED_lANGUAGE"}
                        text={"NEWS_FEED_lANGUAGE"}
                        lang={selectedInterfaceLanguage}
                        headingStyle={false}
                      />
                    }>
                    <Select
                      defaultValue={newsFeedLId}
                      mode="multiple"
                      style={{
                        position: "relative",
                        top: "10px",
                      }}>
                      {userSelectedNewsFeedData?.map((lang) => {
                        return (
                          <>
                            <Option value={lang?.newsfeed_lang_id}>{lang?.name}</Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                      {
                        <TranslateWord
                          key={"UPDATE"}
                          text={"UPDATE"}
                          lang={selectedInterfaceLanguage}
                          headingStyle={false}
                        />
                      }
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
            <TabPane
              tab={
                <TranslateWord
                  key={"LOCATION"}
                  text={"LOCATION"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={false}
                />
              }
              key="location">
              <div style={{ marginBottom: "30px", marginTop: "-3vh" }}>
                <h2>
                  {
                    <TranslateWord
                      key={"LOCATION"}
                      text={"LOCATION"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  }
                </h2>
              </div>
              <div style={{ maxHeight: "60vh", overflow: "auto", overflowX: "hidden" }}>
                <Form
                  form={selectingInterfaceForm}
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={submitLocation}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        name="location"
                        label={
                          <TranslateWord
                            key={"LOCATION"}
                            text={"LOCATION"}
                            lang={selectedInterfaceLanguage}
                            headingStyle={false}
                          />
                        }>
                        <Input name="location" />
                      </Form.Item>

                      <Row gutter={5}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Item
                            name="state"
                            label={
                              <TranslateWord
                                key={"STATE"}
                                text={"STATE"}
                                lang={selectedInterfaceLanguage}
                                headingStyle={false}
                              />
                            }>
                            <Input name="state" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Item
                            name="district"
                            label={
                              <TranslateWord
                                key={"DISTRICT"}
                                text={"DISTRICT"}
                                lang={selectedInterfaceLanguage}
                                headingStyle={false}
                              />
                            }>
                            <Input name="district" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={5}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Item
                            name="mandal"
                            label={
                              <TranslateWord
                                key={"MANDAL"}
                                text={"MANDAL"}
                                lang={selectedInterfaceLanguage}
                                headingStyle={false}
                              />
                            }>
                            <Input name="mandal" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                          <Form.Item
                            name="village"
                            label={
                              <TranslateWord
                                key={"VILLAGE_AREA_LOCALITY"}
                                text={"VILLAGE_AREA_LOCALITY"}
                                lang={selectedInterfaceLanguage}
                                headingStyle={false}
                              />
                            }>
                            <Input name="village" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                          {
                            <TranslateWord
                              key={"UPDATE"}
                              text={"UPDATE"}
                              lang={selectedInterfaceLanguage}
                              headingStyle={false}
                            />
                          }
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </TabPane>
            <TabPane
              tab={
                <TranslateWord
                  key={"WHAT_INTERESTES_YOU"}
                  text={"WHAT_INTERESTES_YOU"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={false}
                />
              }
              key="whatInterestsyou">
              <div style={{ marginBottom: "30px", marginTop: "-3vh" }}>
                <h2>
                  {" "}
                  {
                    <TranslateWord
                      key={"WHAT_INTERESTES_YOU"}
                      text={"WHAT_INTERESTES_YOU"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  }
                </h2>
              </div>
              <div
                style={{
                  maxHeight: "220px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}>
                <Row gutter={16} justify={"space-between"} className="specific-badge">
                  {categoriesData?.map((record) => {
                    return (
                      <>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={12}
                          lg={8}
                          xl={8}
                          xxl={8}
                          style={{ marginTop: "15px" }}>
                          <Badge className="custom-badge" count={selectedCounts[record.category_id]}>
                            <Button
                              key={record.category_id}
                              onClick={() => showModal(record.category_id)}
                              style={{
                                marginBottom: "5px",
                                width: "100%",
                                borderRadius: "8px",
                              }}>
                              {record.category_name}
                              {/* {selectedCounts[record.category_id] > 0 && (
                          <span className="display_numb">
                            {selectedCounts[record.category_id]}
                          </span>
                        )} */}
                            </Button>
                          </Badge>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </div>
              <Row>
                <Col>
                  <Button type="primary" htmlType="submit" style={{ float: "right" }} onClick={submitCategoriesData}>
                    Update
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={
                <TranslateWord
                  key={"AUTO_PLAY"}
                  text={"AUTO_PLAY"}
                  lang={selectedInterfaceLanguage}
                  headingStyle={false}
                />
              }
              key="autoPlay">
              <div style={{ marginBottom: "30px", marginTop: "-3vh" }}>
                <h2>
                  {
                    <TranslateWord
                      key={"AUTO_PLAY"}
                      text={"AUTO_PLAY"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  }
                </h2>
              </div>
              <div>
                <Checkbox style={{ marginLeft: 0 }} name="auto_play" checked={isAutoPlay} onChange={onChangeAutoPlay}>
                  <TranslateWord
                    key={"AUTO_PLAY"}
                    text={"AUTO_PLAY"}
                    lang={selectedInterfaceLanguage}
                    headingStyle={false}
                  />
                </Checkbox>

                <Button type="primary" htmlType="submit" style={{ float: "right" }} onClick={submitAutoPlay}>
                  {
                    <TranslateWord
                      key={"UPDATE"}
                      text={"UPDATE"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={false}
                    />
                  }
                </Button>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Modal>
      <Modal
        className="sub_what_interested"
        title={selectedCategory?.category_name}
        visible={isSubCategoryModalOpen}
        onOk={handleSubCategoryOk}
        onCancel={handleSubCategoryCancel}
        width={600}>
        {" "}
        <Row gutter={16}>
          {selectedCategory?.sub_categories_names?.map((eachsub) => (
            <Col span={8}>
              <Button
                key={eachsub.sub_category_id}
                style={{
                  color: selectedLinks[selectedCategory.category_id]?.[eachsub.sub_category_id] ? "#CC0001" : "inherit",
                  marginBottom: "15px",
                  width: "12vw",
                  borderRadius: "8px",
                  border: "1px solid lightgray",
                }}
                onClick={() => handleLinkClick(selectedCategory.category_id, eachsub.sub_category_id)}>
                {eachsub.sub_category_name}
              </Button>
            </Col>
          ))}
        </Row>
        <Button className="select_deselect_btn" onClick={() => handleSelectAll(selectedCategory.category_id)}>
          {selectAllLable}
        </Button>
      </Modal>
    </div>
  );
};

export default SettingsModel;
