import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import EmojiSelector from "../Components/Sections/Common/EmojiSelector";
import MorEellipsisIconAbout from "../Components/Sections/Common/MorEellipsisIconAbout";
import adv1 from "../imgaes/adv1.svg";
import AdvtHereImg from "../imgaes/AdvtHereImg.png";
import noImg from "../imgaes/noImg.svg";
import noImgJPG from "../imgaes/noImgJPG.jpg";
import smileGif from "../imgaes/smileGif.gif";
import cryIcon from "../imgaes/cryIcon.gif";
import heartIcon from "../imgaes/heartIcon.gif";
import angryIcon from "../imgaes/angryIcon.gif";
import likeIcon from "../imgaes/likeIcon.gif";
import wowIcon from "../imgaes/wowIcon.gif";
import videoPlayIcon from "../imgaes/videoPlayIcon.svg";
import ReactPlayer from "react-player/lazy";
import { shareStoryUrl } from "../utils/config.js";

import ChatComponent from "./ChatComponent/ChatComponent.js";
import { Avatar, Badge, Button, Card, Col, Dropdown, Row, Tag, Tooltip } from "antd";
import Emojis from "./sharedComponents/Emojis.js";
import MorEellipsisIcon from "./Sections/Common/MorEellipsisIcon.js";
import whatsappIcon from "../imgaes/whatsappIcon.svg";
import shareIcon from "../imgaes/shareIcon.svg";
import facebookIcon from "../imgaes/facebookIcon.svg";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { useDispatch, useSelector } from "react-redux";
import { getStoryDetailsPage } from "../Store/StoryDetailsSlice.js";
import { getAllCategoryData } from "../Store/categoryWiseSlice.js";
import { getAllTrendingStories } from "../Store/trendingSlice.js";

import TranslateWord from '../Components/sharedComponents/TranslateWord.js'

let rec = [];
function About() {
  const location = useLocation();
  // window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  let storyId;
  storyId = queryParams.get("param1");
  const sharingUrl = `${shareStoryUrl}/about?param1=${storyId}`;
  const categoryType = queryParams.get("category");
  const subcategory = queryParams.get("subcategory");
  const [latestfive, setLatestfive] = useState([]);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { data: trendingData, status: trendingLoading } = useSelector((state) => state.trendingList);
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const {
    data: allCategoryWiseData,
    status: categoryWiseStatus,
    error: categoryWiseError,
  } = useSelector((state) => state.categoryWiseList);
  const navigate = useNavigate();
  useEffect(() => {
    let lang = selectedInterfaceLanguage;
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === lang?.name;
    });
    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    const payload = {
      category_name: categoryType,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 5,
      sub_category_name: subcategory,
      // login_user_id:loggedInUserData?.user_id
    };
    dispatch(getStoryDetailsPage(storyId));
    if (categoryType === "Trending") {
      const trendingpayload = {
        news_feed_lang_ids: feedLangPayload,
        page: 0,
        pageSize: 50,
        login_user_id: loggedInUserData?.user_id,
      };
      dispatch(getAllTrendingStories(trendingpayload));
    } else {
      if (payload?.news_feed_lang_ids?.length) dispatch(getAllCategoryData(payload));
    }
  }, [storyId, feedLanguageData, userSelectedNewsFeedData, selectedInterfaceLanguage]);
  useEffect(() => {
    if (categoryType === "Trending") setLatestfive(trendingData);
    else setLatestfive(allCategoryWiseData);
  }, [allCategoryWiseData, trendingData]);
  const { data: selectedStory, status, error } = useSelector((state) => state.storyDetailsPageList);

  // const [trendingData, setTrendingData] = useState(
  //   JSON.parse(localStorage.getItem("allStoriesList")) ?? []
  // );

  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
  };

  console.log("aboutData", trendingData);

  // useEffect(()=>{
  //   let newsData = trendingData?.filter((record) => record?.id == storyId);
  //   setSelectedStory(newsData)
  // },[selectedStory])

  const tagsList = selectedStory?.data?.[0]?.tags;
  const categoryList = [];
  const subCategoryList = [];

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const extractContent = (fullDescription) => {
    // Decode HTML entities
    let decodedDescription = decodeHtml(fullDescription);
    console.log("decodedDescription" + JSON.stringify(decodedDescription));

    // Replace Tweet URLs with embed code (without script tag)
    // Remove the ref_src query parameter from the anchor tags
    let modifiedDescription = decodedDescription.replace(/\?ref_src=twsrc%5Etfw">[^<]*<\/a>/g, "</a>");

    // Replace Twitter status URLs with the embedded tweet blockquote
    modifiedDescription = modifiedDescription.replace(
      /https:\/\/twitter\.com\/[^\/]+\/status\/(\d+)/g,
      (match, tweetId) =>
        `<blockquote class="twitter-tweet">
        <a href="https://twitter.com/twitter/statuses/${tweetId}"></a>
    </blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`
    );

    // Replace LinkedIn iframes with their src URL
    modifiedDescription = modifiedDescription.replace(
      /<p>\s*<iframe[^>]*src="([^"]*linkedin\.com\/embed\/feed\/update\/[^"]*)"[^>]*><\/iframe>\s*<\/p>/g,
      (match, url) => `<p>${url}</p>`
    );

    //   modifiedDescription = modifiedDescription.replace(
    //     /https:\/\/www\.linkedin\.com\/embed\/feed\/update\/urn:li:(ugcPost):(\d+)(\?compact=1)?/g,
    //     (match) =>
    //     `<iframe src="${match}" height="550" width="${404}" frameborder="0" allowfullscreen="" scrolling="no"; title="Embedded post"></iframe>`
    // );

    // Replace LinkedIn UGC with specific height and width based on the compact parameter
    modifiedDescription = modifiedDescription.replace(
      /https:\/\/www\.linkedin\.com\/embed\/feed\/update\/urn:li:(ugcPost):(\d+)(\?compact=1)?/g,
      (match, postType, postId, compact) => {
        const height = compact ? 350 : 690;
        const width = compact ? 804 : 404;
        return `<iframe src="${match}" height="${height}" width="${width}" frameborder="0" allowfullscreen="" scrolling="no" title="Embedded post"></iframe>`;
      }
    );

    // Check if there are YouTube iframes and replace the width and height values for all occurrences
    if (modifiedDescription.includes("youtube")) {
      // Use a regex to target all YouTube iframes (including youtube.com and youtube-nocookie.com)
      modifiedDescription = modifiedDescription.replace(
        /<iframe[^>]+src="[^"]*(youtube\.com|youtube-nocookie\.com)[^"]*"[^>]*>/g,
        (match) =>
          match
            .replace(/width="\d+"/, 'width="68%"') // Apply width=100%
            .replace(/height="\d+"/, 'height="350"') // Apply height=250
      );
    }

    modifiedDescription = modifiedDescription.replace(
      /https:\/\/www\.linkedin\.com\/embed\/feed\/update\/urn:li:share:(\d+)/g,
      (match) =>
        `<iframe src="${match}" height="834" width="404" frameborder="0" allowfullscreen="" scrolling="no"; title="Embedded post"></iframe>`
    );

    return modifiedDescription;
  };

  useEffect(() => {
    // Load Twitter widgets script after rendering content
    if (window.twttr) {
      window.twttr.widgets.load();
    } else {
      // Dynamically load the Twitter widget script if it's not loaded yet
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      script.onload = () => {
        if (window.twttr) {
          window.twttr.widgets.load();
        }
      };
      document.body.appendChild(script);
    }

    console.log("hhhhhhhmmmmmm");
  }, [extractContent]);
  selectedStory?.data?.[0]?.categories.forEach((category) => {
    // Push unique names
    if (!categoryList.includes(category.name)) {
      categoryList.push(category.name);
    }

    // Push unique sub-categories
    category.sub_categories.forEach((subCategory) => {
      if (!subCategoryList.includes(subCategory)) {
        subCategoryList.push(subCategory);
      }
    });
  });

  const storyData = selectedStory ?? [];
  console.log("storyData", storyData);
  localStorage.setItem("storyData", JSON.stringify(storyData));
  // let latestfive = trendingData
  //   ?.filter(
  //     (record) =>
  //       record.categories.some((category) => category.name === categoryType) &&
  //       record?.id !== +storyId
  //   )
  //   .slice(0, 5);

  //  let latestfive = []
  // if (categoryType === "foryou") {
  //   latestfive = JSON.parse(localStorage.getItem("allStoriesList"))?.slice(
  //     0,
  //     5
  //   );
  // }
  const selectedCategories = ["Politics", "Sports", "Technology", "Entertainment"];

  const eachData = trendingData?.data
    ?.filter((record) => record.categories.some((category) => selectedCategories.includes(category.name)))
    .slice(0, 4);
  console.log("eachData", eachData);
  const getLatestData = () => {
    dispatch(getStoryDetailsPage(storyId));
    // navigate(`/loading`);
    // setTimeout(() => {
    //   navigate(`/about?param1=${storyId}&category=${categoryType}`);
    // }, 1);
    // const data = JSON.parse(localStorage.getItem("allStoriesList")) ?? [];
    //  rec = data?.filter((record) => record?.id == storyId);
    // // setSelectedStory(rec)
    // if (rec?.length > 0)
    //   setTimeout(() => {
    //     navigate(`/about?param1=${rec[0]?.id}&category=${categoryType}`);
    //   }, 10);

    // if(rec?.length > 0)  setTimeout(()=>{ navigate(`/`)},10);
  };
  // useEffect(()=>{
  //   setSelectedStory(rec)
  // },[rec])

  const getSelectedStories = (type, selectedType) => {
    // window.location.pathname = ''
    navigate(`/SelectedTypeStories?type=${type}&selectedType=${selectedType}`);

    // navigate(`/${type}/${type}/${selectedType}`);
    // window.location.pathname  = `selectedStories/${type}/${selectedType}`
  };

  const goToSourceWitAuthorId = (input_user_id) => {
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
  };
  const gotoWhatsAppGroup = () => {
    window.open("https://whatsapp.com/channel/0029Va50Xpj5vKA5h28WpL0q", "_blank");
  };
  const gotoTelegramGroup = () => {
    window.open("https://t.me/+g0cU7G261WQ4MTRl", "_blank");
  };

  const items = [
    {
      key: "1",
      label: (
        <TwitterShareButton url={sharingUrl} title={"title"} className="Demo__some-network__share-button">
          <XIcon size={25} round />
          &nbsp;Twitter
        </TwitterShareButton>
      ),
    },
    {
      key: "2",
      label: (
        <TelegramShareButton url={sharingUrl} title={"title"} className="Demo__some-network__share-button">
          <TelegramIcon size={25} round />
          &nbsp;Telegram
        </TelegramShareButton>
      ),
    },
    {
      key: "3",
      label: (
        <LinkedinShareButton url={sharingUrl} className="Demo__some-network__share-button">
          <LinkedinIcon size={25} round /> Linkedin
        </LinkedinShareButton>
      ),
    },
    {
      key: "4",
      label: (
        <TumblrShareButton url={sharingUrl} className="Demo__some-network__share-button">
          <TumblrIcon size={25} round /> Tumblr
        </TumblrShareButton>
      ),
    },
  ];
  return (
    <>
      <section className="bg-body section-space-less30">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 mb-30">
              <div className="news-details-layout1">
                <div className="breadcrumbs-area">
                  <div className="breadcrumbs-content">
                    <ul>
                      <li onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
                        Home /{" "}
                      </li>
                      <li onClick={() => navigate(`/${categoryType}`)} style={{ cursor: "pointer" }}>
                        {categoryType != undefined && categoryType}{" "}
                      </li>
                      {subcategory !== "All" && subcategory !== null && <li> / {subcategory} </li>}
                    </ul>
                  </div>
                </div>

                {selectedStory?.data?.map((record) => {
                  const originalDate = new Date(record?.pubDate);
                  const currentDate = new Date();
                  const timeDifference = currentDate - originalDate;
                  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                  let displayTime;
                  if (timeDifference < 24 * 60 * 60 * 1000) {
                    // Less than 24 hours
                    displayTime =
                      hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Less than a min ago";
                  } else {
                    // More than 24 hours
                    displayTime = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                  }
                  return (
                    <>
                      <div className="position-relative mb-30 post_img_content">
                        {record?.image_urls?.[0] ? (
                          <img
                            src={record?.image_urls?.[0]}
                            alt="news"
                            className="img-fluid"
                            style={{ width: "650px", height: "366px", objectFit: "contain", backgroundColor: "" }} //me //27-09
                          />
                        ) : record?.video_url ? (
                          <>
                            {" "}
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <ReactPlayer
                                muted={true}
                                controls={true}
                                url={record?.video_url}
                                style={{ width: "650px", height: "366px", objectFit: "contain", backgroundColor: "" }} //me //27-09
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <img src={noImg} alt="No-Image" />
                          </>
                        )}
                      </div>
                      <h2 className="title-semibold-dark size-c30">
                        <strong
                          className={`${
                            record?.language === "తెలుగు"
                              ? "telugu-text-title_lg"
                              : "english-text-title_lg"
                          }`}>
                          {record?.title
                            .replace(/<[^>]*>/g, "")
                            .replace(/&nbsp;|&emsp;/g, "")
                            .trim()}
                        </strong>
                      </h2>
                      <div className="row">
                        <div className="col-lg-8">
                          <ul className="post-info-dark mb-30">
                            <li>
                              <a>
                                <i className="fa fa-user" aria-hidden="true"></i>
                                <span
                                  onClick={() => {
                                    goToSourceWitAuthorId(record?.user_id);
                                  }}>
                                  {record?.author}
                                </span>
                              </a>
                            </li>
                            <li>
                              <a style={{ cursor: "none" }}>
                                <i className="fa fa-clock-o" aria-hidden="true"></i>
                                {/* {hours < 24 ? `${hours} hrs ago` :originalDate} */}
                                {displayTime}
                                {/* {days > 0
                                    ? `${days} days ago`
                                    : hours > 0
                                    ? `${hours} hrs ago`
                                    : minutes > 0
                                    ? `${minutes} min ago`
                                    : "Less than a min ago"} */}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <Emojis record={record} sendLatestData={getLatestData} />
                              &emsp; &emsp; &emsp; &emsp;
                              {record?.emoji_comments?.map((emj) => {
                                const count = emj?.count;
                                return (
                                  <>
                                    <Badge count={emj?.count} size="small" placement="start">
                                      {/* <Avatar size="small" src={<img style={{width:'25px',height:'25px',cursor:'pointer'}} src={angryIcon}/>} /> */}
                                      {emj?.emoji_name === "angry" && (
                                        <Avatar
                                          size="small"
                                          src={
                                            <img
                                              style={{
                                                width: "18px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              src={angryIcon}
                                            />
                                          }
                                        />
                                      )}
                                      {emj?.emoji_name === "love" && (
                                        <Avatar
                                          size="small"
                                          src={
                                            <img
                                              style={{
                                                width: "18px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              src={heartIcon}
                                            />
                                          }
                                        />
                                      )}
                                      {emj?.emoji_name === "like" && (
                                        <Avatar
                                          size="small"
                                          src={
                                            <img
                                              style={{
                                                width: "18px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              src={likeIcon}
                                            />
                                          }
                                        />
                                      )}
                                      {emj?.emoji_name === "laugh" && (
                                        <Avatar
                                          size="small"
                                          src={
                                            <img
                                              style={{
                                                width: "18px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              src={smileGif}
                                            />
                                          }
                                        />
                                      )}
                                      {emj?.emoji_name === "sad" && (
                                        <Avatar
                                          size="small"
                                          src={
                                            <img
                                              style={{
                                                width: "18px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              src={cryIcon}
                                            />
                                          }
                                        />
                                      )}
                                      {emj?.emoji_name === "wow" && (
                                        <Avatar
                                          size="small"
                                          src={
                                            <img
                                              style={{
                                                width: "18px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              src={wowIcon}
                                            />
                                          }
                                        />
                                      )}
                                    </Badge>
                                  </>
                                );
                              })}
                              {/* <EmojiSelector /> */}
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4">
                          <div className="post-share-area item-shadow-1">
                            <ul className="social-default pull-right">
                              <li>
                                <WhatsappShareButton
                                  url={`${shareStoryUrl}/about?param1=${record?.id}`}
                                  className="Demo__some-network__share-button">
                                  <img src={whatsappIcon} alt="icon" className="icons_img" width={24} height={24} />
                                </WhatsappShareButton>
                              </li>
                              <li>
                                <FacebookShareButton url={sharingUrl} className="Demo__some-network__share-button">
                                  <FacebookIcon size={26} round />
                                  {/* <img
                                          src={facebookIcon}
                                          alt="icon"
                                          className="icons_img"
                                          width={25}
                                          height={25}
                                        /> */}
                                </FacebookShareButton>
                              </li>
                              <li>
                                <Tooltip title="Coming soon" color="gray">
                                  <a className="instgram" target="_blank" style={{ width: "25px", height: "25px" }}>
                                    <i
                                      className="fa fa-instagram"
                                      aria-hidden="true"
                                      style={{ position: "relative", top: "-3px" }}></i>
                                  </a>
                                </Tooltip>
                              </li>

                              <li>
                                {/* <a href="" className="share_more">                                                               
                                                                <div className="c-share">
                                                                    <input className="c-share__input" type="checkbox" id="checkbox" />
                                                                    <label className="c-share__toggler" for="checkbox">
                                                                        <i className="fa fa-share" aria-hidden="true" ></i>
                                                                    </label>

                                                                    <ul className="c-share_options" data-title="Share" >
                                                                        <div className='share_mre'>
                                                                            <li><a href="https://www.linkedin.com/" target='_blank'><i className="fa fa-linkedin" aria-hidden="true"></i> LinkedIn </a></li>
                                                                            <li><a href="https://twitter.com/login" target='_blank'><i className="fa fa-twitter" aria-hidden="true"></i> Twitter </a></li>
                                                                            <li><a title="youtube" href="https://www.youtube.com/@Politikosnews" target="_blank"><i className="fa fa-youtube-play" aria-hidden="true"></i> Youtube</a></li>
                                                                            <li><a title="vimeo" href="https://vimeo.com/user205451233" target="_blank"><i className="fa fa-vimeo" aria-hidden="true"></i> Vimeo</a></li>
                                                                            <li><a title="pinterest" href="https://in.pinterest.com/imwpolitikos/" target="_blank"><i className="fa fa-pinterest" aria-hidden="true"></i> Pinterest</a></li>
                                                                            <li><a title="tumblr" href="https://www.tumblr.com/imwpolitikos" target="_blank"><i className="fa fa-tumblr" aria-hidden="true"></i> Tumblr</a></li>
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                            </a> */}
                                <Dropdown
                                  menu={{
                                    items,
                                  }}
                                  placement="bottomRight"
                                  // arrow
                                >
                                  <img src={shareIcon} alt="icon" className="icons_img" width={25} height={25} />
                                </Dropdown>
                              </li>
                              <li className="more_about">
                                {" "}
                                <MorEellipsisIcon
                                  story={record}
                                  sendLatestData={getLatestData}
                                  page="storyDetails"
                                  bookMarkStoryId={record?.id}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="title-semibold-dark para">
                        <strong
                          className={`${
                            record?.language === "తెలుగు"
                              ? "telugu-text"
                              : "english-text"
                          }`}>
                          {extractContent(record?.description)
                            .replace(/<[^>]*>/g, "")
                            .replace(/&nbsp;|&emsp;/g, "")
                            .trim()}
                        </strong>
                      </div>
                      {/* <h4 className="title-semibold-dark para">{record?.description}</h4>                                 */}
                    </>
                  );
                })}
                {/* <ul className="blog-tags item-inline">
                  <li>
                    <a href="/">Election</a>
                  </li>
                  <li>
                    <a href="/">Politics</a>
                  </li>
                  <li>
                    <a href="/">Karnataka</a>
                  </li>
                </ul> */}
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ width: "120px" }}>                    
                  <TranslateWord
                               key={"TAGS"}
                               text={"TAGS"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={false}
                             /> 
                    : &nbsp;</div>
                  <div>
                    {tagsList?.map((tag) => {
                      return (
                        <Tag
                          color="blue"
                          style={{
                            padding: "5px 15px",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          className={`${
                            selectedStory?.data?.[0]?.language === "తెలుగు"
                              ? "telugu-text"
                              : "english-text"
                          }`}
                          // onClick={() => {
                          //   getSelectedStories("Tags", tag);
                          // }}
                        >
                          {tag}
                        </Tag>
                      );
                    })}
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ width: "120px" }}>
                    
                  <TranslateWord
                               key={"CATEGORIES"}
                               text={"CATEGORIES"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={false}
                             />  
                    
                    : &nbsp;</div>
                  <div>
                    {categoryList?.map((category) => {
                      return (
                        <Tag
                          color="blue"
                          style={{
                            padding: "5px 15px",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          className={`${
                            selectedStory?.data?.[0]?.language === "తెలుగు"
                              ? "telugu-text"
                              : "english-text"
                          }`}
                          // onClick={() => {
                          //   getSelectedStories("Category", category);
                          // }}
                        >
                          {category}
                        </Tag>
                      );
                    })}
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ width: "120px" }}>
                  <TranslateWord
                               key={"SUB_CATEGORIES"}
                               text={"SUB_CATEGORIES"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={false}
                             />
                     : &nbsp;</div>
                  <div>
                    {subCategoryList?.map((category) => {
                      return (
                        <Tag
                          color="blue"
                          style={{
                            padding: "5px 15px",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          className={`${
                            selectedStory?.data?.[0]?.language === "తెలుగు"
                              ? "telugu-text"
                              : "english-text"
                          }`}
                          // onClick={() => {
                          //   getSelectedStories("Sub category", category);
                          // }}
                        >
                          {category}
                        </Tag>
                      );
                    })}
                  </div>
                </div>
                <div className="post-share-area politikos_share mt-20">
                  <ul className="social-default">
                    <li className="whatsapp">
                      <span onClick={gotoWhatsAppGroup} style={{ cursor: "pointer" }}>
                        {/* <img src="img/imw_img/whatsapp.png" alt="adv" className="img-fluid" /> */}
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        <span>
                        <TranslateWord
                               key={"JOINNOW"}
                               text={"JOINNOW"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={false}
                             />
                        </span>
                      </span>
                    </li>
                    <li className="telegram">
                      <span onClick={gotoTelegramGroup} style={{ cursor: "pointer" }}>
                        <img src="img/imw_img/telegram.png" alt="adv" className="img-fluid" />
                        <span>
                        <TranslateWord
                               key={"JOINNOW"}
                               text={"JOINNOW"}
                               lang={selectedInterfaceLanguage}
                               headingStyle={false}
                             />
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>

                {/* <ChatComponent storyId={storyId} /> */}
              </div>
            </div>
            <div className="ne-sidebar sidebar-break-md col-lg-3 col-md-12">
              <div className="sidebar-box">
                <div className="ne-banner-layout1 text-center">
                  <a>
                    <img src={AdvtHereImg} alt="AdvtHereImg" className="img-fluid" />
                  </a>
                </div>
              </div>
              <div className="sidebar-box">
                <div className="topic-border color-cod-gray mb-30">
                  <div className="topic-box-lg color-cod-gray">{`Latest from ${categoryType}`}</div>
                </div>
                {latestfive?.data?.slice(0, 5)?.map((record) => {
                  const originalDate = new Date(record?.pubDate);
                  const currentDate = new Date();
                  const timeDifference = currentDate - originalDate;
                  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                  let displayTime;
                  if (timeDifference < 24 * 60 * 60 * 1000) {
                    // Less than 24 hours
                    displayTime =
                      hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Less than a min ago";
                  } else {
                    // More than 24 hours
                    displayTime = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                  }
                  return (
                    <>
                      <div className="position-relative mb30-list bg-body subpages">
                        <div className="media">
                          <a className="img-opacity-hover">
                            {record?.image_urls?.[0] ? (
                              <img src={record?.image_urls?.[0]} alt="news" className="img-fluid" />
                            ) : record?.video_url ? (
                              <>
                                <Link to={`/about?param1=${record?.id}&category=${categoryType}`}>
                                  <span>
                                    <img style={imageStyle} src={videoPlayIcon} />
                                  </span>{" "}
                                  <video
                                    style={{
                                      width: "113px",
                                      height: "105px",
                                      backgroundColor: "gray",
                                    }}
                                    muted
                                    preload="metadata"
                                    src={`${record?.video_url}#t=0.1`}></video>
                                </Link>
                              </>
                            ) : (
                              <>
                                <img src={noImgJPG} alt="No-Image" />
                              </>
                            )}
                          </a>
                          <div className="media-body">
                            <h3 className={`${record?.language === "తెలుగు" ? 'title-medium-dark-telugu' : 'title-medium-dark'}`}>
                              <strong
                                className={`${
                                  record?.language === "తెలుగు"
                                    ? "telugu-text-title"
                                    : "english-text-title"
                                }`}>
                                <Link to={`/about?param1=${record?.id}&category=${categoryType}`}>
                                  {" "}
                                  {record?.title
                                    .replace(/<[^>]*>/g, "")
                                    .replace(/&nbsp;|&emsp;/g, "")
                                    .trim()}
                                </Link>
                              </strong>
                            </h3>
                            <div className="post-date-dark">
                              <ul style={{ marginBottom: "-1em" }}>
                                <li>
                                  {displayTime}
                                  {/* {days > 0
                                      ? `${days} days ago`
                                      : hours > 0
                                      ? `${hours} hrs ago`
                                      : minutes > 0
                                      ? `${minutes} min ago`
                                      : "Less than a min ago"} */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="sidebar-box">
                <div className="ne-banner-layout1 text-center">
                  <a>
                    <img src={AdvtHereImg} alt="AdvtHereImg" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
        </div>
      </section>

      <Row>
        <Col span={24}>
          {" "}
          <div className="heading subpage_bottom">
            <h1>More from the Politikos</h1>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: "5vh" }}>
        {eachData &&
          eachData?.map((record) => {
            return (
              <>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <Card
                    cover={
                      <Link to={`/about?param1=${record?.id}`} class="img-opacity-hover">
                        {record?.image_urls?.[0] ? (
                          <img
                            src={record?.image_urls?.[0]}
                            alt="news"
                            style={{
                              display: "block", // Ensures the image is treated as a block element
                              margin: "0 auto", // Centers the image horizontally
                              maxWidth: "100%", // Ensures the image doesn’t exceed the container width
                              maxHeight: "32vh", // Limits height for small devices
                              objectFit: "contain", // Prevents image distortion
                            }}
                          />
                        ) : record?.video_url ? (
                          <>
                            <img
                              src={record?.video_thumbnail_url}
                              alt="news"
                              style={{
                                display: "block", // Ensures the image is treated as a block element
                                margin: "0 auto", // Centers the image horizontally
                                maxWidth: "100%", // Ensures the image doesn’t exceed the container width
                                maxHeight: "30vh", // Limits height for small devices
                                objectFit: "contain", // Prevents image distortion
                              }}
                            />
                            {/* <video
                                  // style={{
                                  //   width: "99.5%",
                                  //   height: "32vh",
                                  //   position: "relative",
                                  //   top: "-3px",
                                  //   zIndex: "2",
                                  // }}
                                  style={{
                                    display: "block",          // Treats video as a block element
                                    margin: "0 auto",          // Centers the video horizontally
                                    width: "100%",
                                    height: "32vh",
                                    objectFit: "contain",      // Ensures video fits without distortion
                                  }}
                                  preload="metadata"
                                  src={`${record?.video_url}`}
                                  controls
                                  muted></video> */}
                          </>
                        ) : (
                          <>
                            <img src={noImgJPG} alt="No-Image" />
                          </>
                        )}
                      </Link>
                    }>
                    <Link to={`/about?param1=${record?.id}`} class="title-medium-dark size-lg mb-none">
                      {record?.title
                        .replace(/<[^>]*>/g, "")
                        .replace(/&nbsp;|&emsp;/g, "")
                        .trim()?.length > 100
                        ? record?.title
                            .replace(/<[^>]*>/g, "")
                            .replace(/&nbsp;|&emsp;/g, "")
                            .trim()
                            ?.substring(0, 100) + "..."
                        : record?.title
                            .replace(/<[^>]*>/g, "")
                            .replace(/&nbsp;|&emsp;/g, "")
                            .trim()}
                    </Link>
                  </Card>
                </Col>
              </>
            );
          })}
      </Row>
    </>
  );
}

export default About;
