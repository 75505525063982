import React, { Suspense, lazy } from "react";



import { Link } from "react-router-dom";
import { Col, Row, Skeleton } from "antd";
import Trending from "../Trending";
import BreakingNewsMain from "../Sections/Breaking News/BreakingNewsMain";
import PoliticsSectionDB from "../Sections/PoliticsSection/PoliticsSectionDB";
import TranslateWord from "../sharedComponents/TranslateWord";
import { useSelector } from "react-redux";
import EntertainmentSectionDB from "../Sections/EntertainmentSection/EntertainmentSectionDB";
import SportsSectionDB from "../Sections/SportsSection/SportsSectionDB";
import BusinessSectionDB from "../Sections/BusinessSection/BusinessSectionDB";
import TechnologySectionDB from "../Sections/TechnologySection/TechnologySectionDB";
import AdvtHereImg from "../../imgaes/AdvtHereImg.png";
import adv1 from "../../imgaes/Advs/adv1.svg";
import adv2 from "../../imgaes/Advs/adv2.svg";
import adv3 from "../../imgaes/Advs/adv3.svg";
import adv4 from "../../imgaes/Advs/adv4.svg";
import adv5 from "../../imgaes/Advs/adv5.svg";
import adv6 from "../../imgaes/Advs/adv6.svg";
import adv7 from "../../imgaes/Advs/adv7.svg";
import adv8 from "../../imgaes/Advs/adv8.svg";
import adv9 from "../../imgaes/Advs/adv9.svg";
import AdComponent from "../Adsens/AdComponent";
const LazyTrending = lazy(() => import("../Trending"));
const CategoriesSectionDB = () => {
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { politicsSelectedLang } = useSelector((state) => state.politicsList);
  const { entertainmentSelectedLang } = useSelector((state) => state.entertainmentList);
  const { sportsSelectedLang } = useSelector((state) => state.sportsList);
  const { businessSelectedLang } = useSelector((state) => state.businessList);
  const { technologySelectedLang } = useSelector((state) => state.technologyList);
  return (
    <>
      <div style={{ marginBottom: "3vh" }}>
        <Trending />
        <BreakingNewsMain />
      </div>
      <div>
        <hr></hr>
      </div>
      <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
          <PoliticsSectionDB
            sectionType={
              <TranslateWord key={"POLITICS"} text={"POLITICS"} headingStyle={true} lang={politicsSelectedLang} />
            }
          />
          <hr></hr>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border" style={{ borderLeft: "2px dashed gray" }}>
          {/* <div className="sidebar-box">           
            <AdComponent adClient="ca-pub-5294377955584373" adSlot="6745023357" />
          </div> */}
           <div className="sidebar-box">
            <a>
              <img src={adv1} alt="ad" className="img-fluid" style={{height:'260px'}}/>
            </a>
            <a>
              <img src={adv2} alt="ad" className="img-fluid" style={{height:'260px'}}/>
            </a>
          </div>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
          <EntertainmentSectionDB
            sectionType={
              <TranslateWord
                key={"ENTERTAINMENT"}
                text={"ENTERTAINMENT"}
                headingStyle={true}
                lang={entertainmentSelectedLang}
              />
            }
          />
          <hr></hr>
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border" style={{ borderLeft: "2px dashed gray" }}>
          <div className="sidebar-box">
            <a>
              <img src={adv3} alt="ad" className="img-fluid" style={{height:'260px'}}/>
            </a>
            <a>
              <img src={adv4} alt="ad" className="img-fluid" style={{height:'260px'}}/>
            </a>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
          <SportsSectionDB
            sectionType={<TranslateWord key={"SPORTS"} text={"SPORTS"} headingStyle={true} lang={sportsSelectedLang} />}
          />
          <hr></hr>
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border" style={{ borderLeft: "2px dashed gray" }}>
          <div className="sidebar-box">
            <a>
              <img src={adv5} alt="ad" className="img-fluid" style={{height:'260px'}} />
            </a>
            <a>
              <img src={adv6} alt="ad" className="img-fluid"style={{height:'260px'}} />
            </a>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
          <BusinessSectionDB
            sectionType={
              <TranslateWord key={"BUSINESS"} text={"BUSINESS"} headingStyle={true} lang={businessSelectedLang} />
            }
          />
          <hr></hr>
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border" style={{ borderLeft: "2px dashed gray" }}>
          <div className="sidebar-box">
            {/* <a>
              <img src={adv7} alt="ad" className="img-fluid" style={{height:'260px'}}/>
            </a> */}
            <a>
              <img src={adv8} alt="ad" className="img-fluid"/>
            </a>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
        <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
          <TechnologySectionDB
            sectionType={
              <TranslateWord key={"TECHNOLOGY"} text={"TECHNOLOGY"} headingStyle={true} lang={technologySelectedLang} />
            }
          />
          <hr></hr>
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} className="responsive-border" style={{ borderLeft: "2px dashed gray" }}>
          {/* <div className="sidebar-box">
          <AdComponent adClient="ca-pub-5294377955584373" adSlot="6745023357" />
          </div> */}
           <div className="sidebar-box">
            <a>
              <img src={adv9} alt="ad" className="img-fluid" style={{height:'260px'}}/>
            </a>
            <a>
              <img src={adv1} alt="ad" className="img-fluid" style={{height:'260px'}} />
            </a>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CategoriesSectionDB;
